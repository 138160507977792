// Login actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_TOKEN = "SET_TOKEN";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const LOAD_ALL_UNIVERSES = "LOAD_ALL_UNIVERSES";
export const LOAD_ALL_THREADS = "LOAD_ALL_THREADS";
export const NEW_MESSAGE_IN_THREADS = "NEW_MESSAGE_IN_THREADS";
export const UPDATE_MESSAGE_IN_THREADS = "UPDATE_MESSAGE_IN_THREADS";
export const UPDATE_USER_IN_THREADS = "UPDATE_USER_IN_THREADS";
