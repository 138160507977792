const endPoints = {
  LOGIN: "/api/authentication",
  REFRESH_TOKEN: "/api/token/refresh",
  ANONYMOUS_UNIVERSES: "/api/anonymous/universes",
  ANONYMOUS_UNIVERSES_DETAILS: "/api/anonymous/universes/details",
  ANONYMOUS_UNIVERSES_CATEGORIES_DETAILS: "/api/anonymous/universes/categories/details",
  ANONYMOUS_ALL_UNIVERSES_CATEGORIES_DETAILS: "/api/anonymous/all/universes/categories/details",
  ANONYMOUS_UNIVERSES_CATEGORIES: "/api/anonymous/universes/categories",
  ANONYMOUS_UNIVERSE: "/api/anonymous/universe/",
  UNIVERSES_CATEGORIES_REPAIRMAN: "/api/universes/categories/repairman",
  UNIVERSE: "/api/universe",
  ANONYMOUS_CATEGORIES: "/api/anonymous/categories",
  ANONYMOUS_CATEGORIES_SUBCATEGORIES: "/api/anonymous/categories/subCategories",
  ANONYMOUS_CATEGORY: "/api/anonymous/category/",
  ANONYMOUS_CLIENT: "/api/anonymous/user/client",
  ANONYMOUS_USER_REPAIRMAN: "/api/anonymous/user/repairman",
 
  CATEGORY: "/api/category",
  ANONYMOUS_SERVICES_DETAILS: "/api/anonymous/services/details",
  ANONYMOUS_SERVICES: "/api/anonymous/services",
  ANONYMOUS_SERVICES_PRICES: "/api/anonymous/services/prices",
  ANONYMOUS_SERVICE: "/api/anonymous/service/",
  ANONYMOUS_SERVICES_CATALOG: "/api/anonymous/services/catalog",
  ANONYMOUS_SERVICES_SPECIAL_CATALOG: "/api/anonymous/services/special/catalog",
  ANONYMOUS_BENEFITS_SERVICE: "/api/anonymous/benefits/service",
  ANONYMOUS_BENEFIT: "/api/anonymous/benefit",
  ANONYMOUS_CONTACT: "/api/anonymous/contact",
  ANONYMOUS_SUPERPASS: "/api/anonymous/superpass",
  SERVICES_REPAIRMAN: "/api/services/repairman",
  SERVICE: "/api/service",
  NEW_SERVICE: "/api/new_service",
  ANONYMOUS_SERVICE_SEARCH: "/api/anonymous/service/_search",
  ANONYMOUS_MEDIA_OBJECT: "/api/anonymous/media_object",
  ANONYMOUS_DELETE_MEDIA_OBJECT: "/api/anonymous/delete/media_object",
  THREADS: "/api/threads",
  MESSAGES: "/api/messages",
  MESSAGES_TYPING: "/api/is-typing",
  USERS_REPAIRMAN: "/api/users/repairman",
  USERS_REPAIRMAN_PUBLIC: "/api/anonymous/users/repairmanpublic",
  USERS_CLIENT: "/api/users/client",
  USERS_CLIENT_LIST: "/api/users/client/list",
  USERS_ADMINS: "/api/users/admin",
  USERS_COMMANDS_CLIENTS: "/api/users/commands/clients",
  USERS_COMMANDS_REPAIRMANS: "/api/users/commands/repairmans",
  USERS_DEVIS_CLIENTS: "/api/users/devis/clients",
  USERS_DEVIS_REPAIRMANS: "/api/users/devis/repairmans",
  USERS_REPAIRMAN_UNIVERSES: "/api/users/repairman/universes",
  ANONYMOUS_USERS_REPAIRMAN_UNIVERSES: "/api/anonymous/users/repairman/universemap",
  ANONYMOUS_UNIVERSE_REPAIRMEN: "/api/anonymous/universerepairmen",
  USER: "/api/user",
  USER_ADMIN_REPAIRMAN: "/api/user/admin/repairman",
  ADDRESSES: "/api/addresses",
  BENEFITS_ADMIN: "/api/benefits/admin",
  BENEFITS_REPAIRMAN: "/api/benefits/repairman",
  ANONYMOUS_BENEFIT_DETAIL :"api/anonymous/benefit",
  BENEFIT: "/api/benefit",
  BENEFIT_REPAIRMAN: "/api/benefit/repairman",
  DELIVERY_MODES: "/api/delivery_modes",
  DELIVERY_MODE_TYPES: "/api/anonymous/delivery_mode_types",
  DELIVERY_MODE_TYPE: "/api/anonymous/delivery_mode_type",
  TYPE_COMPANIES: "/api/anonymous/type_companies",
  TYPE_COMPANY: "/api/anonymous/type_company",
  DEVIS: "/api/commands/devis",
  COMMANDS: "/api/commands",
  COMMAND: "/api/command",
  PAIEMENT: "/api/paiement",
  CREATE_PAYMENT_INTENTS: "/api/paiement/create-payment-intents",
  EXPORT_DEVIS: "/api/exports/pdf/devis",
  FORGOT_PASSWORD: "/api/forgot_password",
};
export default endPoints;
